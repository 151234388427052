<template>
  <BaseDialog
    :show="true"
    width="566px"
    :title="title"
    btn1="確認"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div>
      <!-- <p v-if="useSheetExportCenter" class="mb-[8px]">確認匯出後，請至下載中心查看任務與下載匯出檔案</p> -->
      <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <BaseElFormItem label="匯出票券種類" prop="couponType">
          <BaseElSelect
            v-model="formData.couponType"
            style="width: 100%"
            placeholder="請選擇"
          >
            <BaseElSelectOption
              v-for="item in couponTypeConfig"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem label="匯出格式" prop="format">
          <BaseElSelect
            v-model="formData.format"
            style="width: 100%"
            placeholder="請選擇格式"
          >
            <BaseElSelectOption
              v-for="item in exportFormatOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { exportFormatOptions } from '@/config/export'
import { couponTypeConfig } from '@/config/coupon'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
// import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'CouponExportOptionModal',
  components: { BaseDialog },
  props: { title: String },
  emits: ['close', 'confirm'],
  setup (props, { emit }) {
    const { initFormData, formData, formRef } = useBaseForm()
    // const { checkAction } = usePermissions()
    // const useSheetExportCenter = computed(() => checkAction('admin.sheetExportTask.page'))
    // TODO: 改成 props 傳入 useExportTask base on admin.couponXXXXX.createSheetExportTask
    initFormData({
      couponType: 'coupon',
      format: 'excel',
    })
    const formRules = {
      couponType: [noEmptyRules()],
      format: [noEmptyRules()],
    }

    const onConfirm = () => {
      emit('confirm', formData.couponType)
    }
    return {
      onConfirm,
      formData,
      exportFormatOptions,
      formRef,
      formRules,
      couponTypeConfig,
      // useSheetExportCenter,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-input {
  width: 100% !important;
}
::v-deep .el-select {
  width: 100% !important;
}
::v-deep .el-input__inner {
  width: 100% !important
}
</style>
